import React from "react"
import Card1 from "../../components/card1"
import Layout from "../../components/layout"
//import { bdata } from "../../data"
//import { Card } from "semantic-ui-react"
import { graphql } from "gatsby"
//import { renderRichText } from "gatsby-source-contentful/rich-text"
import "../../styles/branches.css"
import { getWords } from "../../helper-functions"
import SEO from "../../components/seo"

function Branches({ data }) {
  const {
    allContentfulBranch: { nodes: Branches },
  } = data

  return (
    <div>
      <Layout>
        <SEO
          title="Branches"
          description="The title of “The oldest technical institution in Asia” that IIT-R proudly holds is very prestigious and a mark of great honour, but at the same time it sets a very high benchmark for our institute. Despite being burdened with such a major task, we are renowned for far exceeding all expectations and constantly increasing the bar of excellence in all of our 17 branches. With such a wide variety of courses, every student has a chance to pursue his/her interests, regardless of the course they are enrolled in and the unity among all the branches is another matter of great pride among the student population, for what better way is there to learn than in a constructive and co-dependant environment."
        />
        <h2 className="dark-color-head">Know Your Branches</h2>
        <p className="normal-para">
          The title of “The oldest technical institution in Asia” that IIT-R
          proudly holds is very prestigious and a mark of great honour, but at
          the same time it sets a very high benchmark for our institute. Despite
          being burdened with such a major task, we are renowned for far
          exceeding all expectations and constantly increasing the bar of
          excellence in all of our 17 branches. With such a wide variety of
          courses, every student has a chance to pursue his/her interests,
          regardless of the course they are enrolled in and the unity among all
          the branches is another matter of great pride among the student
          population, for what better way is there to learn than in a
          constructive and co-dependant environment. The diversity in the
          branches and the wide domain of fields it covers is a perfect melting
          pot of knowledge which not many other institutes can live up to.
        </p>
        <div className="branches-card">
          {Branches.map(Branch => (
            <Card1
              carddata={{
                title: Branch.name,
                content: `${getWords(Branch.sContent.sContent, 13)}...`,
                link: `branches/${Branch.slug}`,
                fluid: Branch.image.fluid,
              }}
            />
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default Branches

export const query = graphql`
  {
    allContentfulBranch(sort: { fields: name }) {
      nodes {
        sContent {
          sContent
          childMarkdownRemark {
            html
          }
        }
        name
        slug
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
// {{
//     allContentfulBranch {
//       nodes {
//         faculty
//         syllabus
//         courseStr
//         slug
//         content {
//           raw
//           references {
//             ... on ContentfulAsset {
//               contentful_id
//             }
//             ... on ContentfulBranch {
//               contentful_id
//               name
//               slug
//             }
//           }
//         }
//         name
//         image {
//           fluid {
//             src
//           }
//         }
//       }
//     }
//   }}
