export function getWords(str, num) {
  return str.split(/\s+/).slice(0, num).join(" ")
}

export function makeUrl(str, slug) {
  return `https://expectations.iitr.ac.in/${str}${slug}`
}

export function updateCoordinates(coord) {
  return coord.map(c => c * 0.75)
}
